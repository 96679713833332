import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
import contactListExport from './contactListExport'
class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected orderModule?: boolean

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey
    // @ts-ignore
    this.orderModule = this.vm.orderModule

    return {
      pageTitle: 'module.contact',
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_CONTACT']),
      search: 'contact.search_keyword',
      // @ts-ignore
      filter: this.getFilter(),
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
      }),
      exportExcel: contactListExport(this.vm),
      displayMode: 'table',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      table: {
        data: <TableDataType>[
          {
            key: 'type',
            label: 'contact.data.type',
            type: 'text',
            text: row => this.vm?.$t(`contact.data.type.${row.type}`),
          },
          {
            key: 'title',
            label: 'data.title',
            type: 'text',
          },
          {
            key: 'name',
            label: 'data.user',
            type: 'text',
            component: () => import('modules/contact/views/contactList/member.vue'),
          },
          {
            key: 'status',
            label: 'data.status',
            type: 'text',
            text: row => this.vm?.$t(`contact.data.status.${row.status}`),
          },
          ...this.getTableOrderId(),
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.name,
        },
      },
      dataAction: {
        update: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          label: 'action.edit',
          route: row => ({ name: 'contact-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          removeTarget: row => row.name,
        },
      },
    }
  }

  getFilter() {
    const filter = {
      type: {
        label: 'contact.data.type',
        type: 'selection',
        // @ts-ignore
        options: (listMeta) => this.vm.$contactType,
        optionTextPrefix: 'contact.data.type',
      },
      status: {
        label: 'data.status',
        type: 'selection',
        // @ts-ignore
        options: (listMeta) => this.vm.$contactStatus,
        optionTextPrefix: 'contact.data.status',
      },
      created_at: {
        label: 'data.created_at',
        type: 'date',
      },
    }
    if(this.orderModule) {
      // @ts-ignore
      filter.order_id = {
        label: 'order.data.id',
        type: 'text',
      }
    }
    return filter
  }

  getTableOrderId() {
    if(!this.orderModule) return []
    return [{
      key: 'order_id',
      label: 'order.data.id',
      component: () => import('./contactList/order.vue'),
    }]
  }
}

export default new listConfig()

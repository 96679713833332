import {ExportExcelInterface} from 'components/list/types/list'

export default (vm: Vue) => <ExportExcelInterface>{
  filename: (time: string) => `${vm?.$t('module.contact')}`,
  sheets: [
    {
      label: 'module.contact',
      data: {
        id: { label: 'contact.data.id', width: 20 },
        created_at: { label: 'data.created_at', width: 18 },
        title: { label: 'data.title', width: 26 },
        name: { label: 'data.person_name', width: 18 },
        email: { label: 'data.email', width: 26 },
        phone: { label: 'data.phone', width: 22 },
        type: {
          label: 'contact.data.type',
          width: 12,
          value: row => vm?.$t(`contact.data.type.${row.type}`),
        },
        status: {
          label: 'data.status',
          width: 12,
          value: row => vm?.$t(`contact.data.status.${row.status}`),
        },
        order_id: { label: 'order.data.id', width: 22 },
        note: {
          label: 'contact.data.note',
          width: 26,
        },
      },
    },
  ]
}
